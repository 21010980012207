.search-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
    }
    
    .search {
    width: 60%;
    display: block;
    padding: 15px;
    border: none;
    outline: none;
    background: none;
    background-color: #fff;
    border-radius: 8px;
    color: dimgrey;
    font-size: 20px;
    font-weight: 300;
    transition: 0.4s ease-out;
    }
    
    .search:focus {
    box-shadow: 0 0 8px 3px #4484c4;
    }
    
    @media screen and (max-width: 685px) {
    .search {
        width: 75%;
    }
    }
    
    @media screen and (max-width: 410px) {
    .search {
        width: 85%;
    }
    }
    